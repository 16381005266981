import $ from "jquery";
import "slick-carousel";

$(".slider").slick({
    infinite: true,
    arrows: false,
    dots: false,
    autoplay: false,
    vertical: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
});

//ticking machine
var percentTime;
var tick;
var time = 0.1;
var progressBarIndex = 0;

$(".progressBarContainer .progressBar").each(function (index) {
    var progress = "<div class='inProgress inProgress" + index + "'></div>";
    $(this).html(progress);
});

function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    tick = setInterval(interval, 10);
}

function interval() {
    if (
        $(
            '.slider .slick-track div[data-slick-index="' +
                progressBarIndex +
                '"]'
        ).attr("aria-hidden") === "true"
    ) {
        progressBarIndex = $(
            '.slider .slick-track div[aria-hidden="false"]'
        ).data("slickIndex");
        startProgressbar();
    } else {
        percentTime += 1 / (time + 10);
        $(".inProgress" + progressBarIndex).css({
            width: percentTime + "%",
        });
        $(".inProgress" + progressBarIndex)
            .parent()
            .parent()
            .addClass("active");

        if (percentTime >= 100) {
            $(".single-item").slick("slickNext");
            progressBarIndex++;
            if (progressBarIndex > 2) {
                progressBarIndex = 0;
            }
            startProgressbar();
        }
    }
}

function resetProgressbar() {
    $(".inProgress").css({
        width: 0 + "%",
    });
    $(".inProgress").parent().parent().removeClass("active");
    clearInterval(tick);
}
startProgressbar();
// End ticking machine

$(".item").click(function () {
    var goToThisIndex = $(this).find("span").data("slickIndex");
    $(".single-item").slick("slickGoTo", goToThisIndex, false);
    startProgressbar();
});
